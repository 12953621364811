function loadAnimations() {
    $('.open-link').magnificPopup({
        type: 'inline',
        midClick: true
    });

    AOS.init({
        duration: 1200
    })
}

$(document).ready(function () {

    loadAnimations();

    var css;
    var currentTime = new Date().valueOf();
    css = document.getElementsByClassName('cssVersion');
    for (var x = 0; x < css.length; x++){
        cssVersion = css[x];
        updatedDependency = $(cssVersion).attr('href') + currentTime;
        $(cssVersion).attr('href', updatedDependency);
    }

    var js;
    js = document.getElementsByClassName('jsVersion');
    for (var x = 0; x < js.length; x++){
        jsVersion = js[x];
        updatedDependency = $(jsVersion).attr('src') + currentTime;
        $(jsVersion).attr('src', updatedDependency);
    }

    $('#slider-image-1').remove().prependTo($('#slider-1'));
    $('#slider-image-2').remove().prependTo($('#slider-2'));
    $('#slider-image-3').remove().prependTo($('#slider-3'));

    $('#slider-heading-1').remove().prependTo($('#slider-section-1'));
    $('#slider-heading-2').remove().prependTo($('#slider-section-2'));
    $('#slider-heading-3').remove().prependTo($('#slider-section-3'));

    $('#intAPI').remove().insertAfter($('#usAPI'));

    $(window).resize(function() {
        if ($(window).width() <= 600) {
            $('#slider-image-1').remove().insertAfter($('#slider-heading-1'));
            $('#slider-image-2').remove().insertAfter($('#slider-heading-2'));
            $('#slider-image-3').remove().insertAfter($('#slider-heading-3'));

            $('#intAPI').remove().insertAfter($('#featured-api-section__balance-transfers'));
        }

        else if ($(window).width() < 768) {
            $('#intAPI').remove().insertAfter($('#featured-api-section__balance-transfers'));
        }

        else {
            $('#slider-image-1').remove().prependTo($('#slider-1'));
            $('#slider-image-2').remove().prependTo($('#slider-2'));
            $('#slider-image-3').remove().prependTo($('#slider-3'));

            $('#slider-heading-1').remove().prependTo($('#slider-section-1'));
            $('#slider-heading-2').remove().prependTo($('#slider-section-2'));
            $('#slider-heading-3').remove().prependTo($('#slider-section-3'));

            $('#intAPI').remove().insertAfter($('#usAPI'));

        }

    })

});

